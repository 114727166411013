import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
	name: 'safeUrl',
	standalone: true
})
export class SafeUrlPipe implements PipeTransform {
	private plaform = inject(PLATFORM_ID);
	private sanitizer = inject(DomSanitizer);

	public transform(value?: string): SafeUrl | string {
		if (!value) {
			return '';
		}

		if (isPlatformBrowser(this.plaform)) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(value);
		}

		return value;
	}
}
